.wp-block-uagb-faq {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-child {
    border-radius: 10px;
    padding: 10px;
    transition: 0.3s;
    border: 2px solid rgb(128 128 128 / 10%);

    &:hover {
      background-color: rgb(128 128 128 / 10%);
    }

    .uagb-faq-questions-button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;

      .uagb-icon {
        display: block;

        &-active {
          display: none;
        }
      }

      .uagb-faq-icon-wrap {
        width: 15px;
        height: 15px;
      }

      .uagb-question {
        font-size: 18px;
        @media (max-width: $small-table) {
          font-size: 16px;
          width: 90%;
        }
      }
    }

    p {
      transition: all 0.35s;
      overflow: hidden;
      max-height: 0;
    }

    &.active {
      box-shadow: $box-shadow;

      &:hover {
        background-color: white;
      }

      .uagb-faq-questions-button {
        .uagb-question {
          font-weight: bold;
        }

        span.uagb-icon {
          display: none;

          &-active {
            display: block;
          }
        }
      }

      p {
        max-height: 100vh;
        overflow: auto;
        padding: 10px;

        a {
          text-decoration: underline;
        }

        iframe {
          max-width: 400px;
          width: 100% !important;
        }
      }
    }
  }
}
