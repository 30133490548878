.wp-block-uagb-slider {
  position: relative;
  max-width: 750px;
  margin: 0 auto;

  @media (950px <= width <= 1210px) {
    max-width: 580px;
  }

  .swiper-wrapper {
    width: calc(100% - 110px);
    position: relative;
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    gap: 5px;
    scroll-snap-align: start;
    align-items: center;
    margin: 0 auto;
    transition: 0.6s ease-in-out;

    @media (width <= 920px) {
      width: 90vw;
    }

    .wp-block-uagb-slider-child {
      width: 100%;
      flex: 0 0 100%;
      display: inline-block;
      vertical-align: middle;
      white-space: break-spaces;
      scroll-snap-align: start;

      div {
        .uagb-container-inner-blocks-wrap,
        .uagb-ifb-content {
          display: flex;
          flex-direction: column;
          gap: 15px;

          figure {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .swiper-button {
    &-prev,
    &-next {
      font-size: 20px;
      position: absolute;
      top: 50%;
      bottom: 50%;
      background-color: #efefef;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      transition: 0.3s;
      cursor: pointer;

      @media (width <= $small-table) {
        width: 30px;
        height: 30px;
        font-size: 16px;
      }

      &:hover {
        box-shadow: $box-shadow;
        background-color: $white;
      }
    }
    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }
  }
}
