@import "@styles/variables.scss";

.btnDownloadApp {
  background-color: #000096;
  color: $white;
  font-weight: bold;
  border-radius: 20px;
  line-height: normal;
  font-size: 17px;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  text-align: center;
  justify-content: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  &:hover {
    filter: $filter-brightness;
  }
}
