.main {
  width: 100%;
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
  }
}
