@import "@styles/variables.scss";

.cookiesContainer {
  z-index: 999;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  right: 0;
  bottom: 10px;
  border-radius: 15px;
  border: none !important;
  box-shadow: 0 0 10px 5px #80808057;
  position: fixed;
  left: 0;
  background-color: $white;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  animation: appear 3s;
}

.cookiesContainer div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cookiesContainer div p strong {
  font-size: 16px;
}

.cookiesContainer div p {
  font-size: 14px;
}

.cookiesContainer div p a {
  margin: 0 5px;
  font-weight: bold;
  text-decoration: underline;
}

@keyframes appear {
  from {
    bottom: -100%;
  }
  to {
    bottom: 10px;
  }
}

@media (max-width: $small-table) {
  .cookiesContainer {
    flex-direction: column;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    align-items: center;
    width: 100%;
  }

  .cookiesContainer div {
    width: 100%;
    align-items: center;
  }

  .buttonAccept {
    width: 100%;
  }

  .cookiesContainer div p strong {
    text-align: center;
  }

  .cookiesContainer div p {
    text-align: justify;
  }

  @keyframes appear {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0px;
    }
  }
}
