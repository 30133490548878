.wp-block-uagb-blockquote {
  &.uagb-blockquote__skin-border {
    border-color: #abb8c3;
    border-left-style: solid;
    border-left-width: 4px;
    padding: 10px 10px 10px 20px;
    background-color: #f4f4f4a3;
  }

  &.uagb-blockquote__skin-quotation {
    .uagb-blockquote {
      position: relative;
      span {
        position: absolute;
        background: #f4f4f4;
        border-radius: 100%;
        padding: 10px;
      }

      .uagb-blockquote__content {
        padding-left: 50px;
      }
    }
  }

  .uagb-blockquote {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .uagb-blockquote__content {
      font-style: italic;
      font-size: 18px;
      line-height: 1.5em;
      text-align: left;
      color: #666;
    }

    footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
      border-top: 1px solid #666;

      .uagb-blockquote__author-wrap {
        display: flex;
        align-items: center;
        gap: 15px;

        img {
          border-radius: 50%;
        }
      }

      .uagb-blockquote__tweet-button {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #1da1f2;
        padding: 8px 15px;
        border-radius: 25px;
        color: $white;

        svg {
          fill: $white;
        }
      }
    }
  }
}

// --------------------------------
figure.wp-block-pullquote {
  border-bottom: 4px solid;
  border-top: 4px solid;
  color: currentColor;
  margin-bottom: 1.75em;
  box-sizing: border-box;
  overflow-wrap: break-word;
  padding: 3em 0;
  text-align: center;

  blockquote {
    display: flex;
    flex-direction: column;
    gap: 1em;

    p {
      font-size: 1.5em;
    }

    cite {
      color: #666;
    }
  }
}

// --------------------------------
.wp-block-quote {
  padding-left: 0;
  padding-right: 1em;
  margin: 0 0 1.75em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  p {
    font-size: 1.5em;
    font-style: italic;
    line-height: 1.6;
    color: #666;
  }

  cite {
    text-align: right;
    display: block;
    font-size: 1.125em;
    font-style: normal;
  }
}

// ------------------------------

.wp-block-cover {
  width: 100%;
  position: relative;
  padding: 1em;
  display: grid;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background-color: $black;
    position: absolute;
    inset: 0;
    z-index: 2;
    opacity: 0.6;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    object-fit: cover;
  }

  .wp-block-cover__inner-container {
    position: relative;
    z-index: 3;
  }
}
