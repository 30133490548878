@import "@styles/variables.scss";

.resolution0070 {
  padding: 0 15px 2.2rem 15px;

  @media (width <= $mobile) {
    margin-top: -15px;
    padding: 0 10px 2.2rem 10px;
  }
  p,
  a {
    color: #63637e;
    font-size: 13px;
    text-align: justify;
    opacity: 1;
  }

  a {
    margin-left: 5px;
    text-decoration: underline;
  }
}
