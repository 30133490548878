.highlight-information {
  padding: 40px 20px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .highlight-information {
    padding: 10px;
  }
}

.vacancy-links {
  padding: 40px 20px;
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    padding: 0;
    gap: 10px;
    margin-top: 20px;

    @media (width <= $mobile) {
      grid-template-columns: 1fr !important;
      height: 270px;
      overflow-y: auto;
      padding-right: 10px;
    }

    li a {
      background-color: $gray-8;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 2px;
      font-weight: 400;
      font-size: 16px;
      padding: 8px 14px;
      color: $purple;
      cursor: pointer;
      transition: $transition;
      text-decoration: underline;
      height: 100%;

      &::after {
        content: "";
        background-image: url("https://static-next-stage.magneto365.com/wordpress/2023/12/28161303/arrow-blue-right.svg");
        background-position: center center;
        width: 5px;
        height: 5px;
        padding: 5px;
        border-radius: 50px;
        display: block;
        background-size: 30%;
        background-repeat: no-repeat;
        transition: 0.2s;
      }

      &:hover {
        background-color: #cfcfde;

        &::after {
          transform: translateX(5px);
        }
      }
    }
  }
}
