.has-background {
  padding: 8px;
}

.has-black-color {
  color: black !important;
}

.has-cyan-bluish-gray-color {
  color: #abb8c3 !important;
}

.has-white-color {
  color: white !important;
}

.has-pale-pink-color {
  color: #f78da7 !important;
}

.has-vivid-red-color {
  color: #cf2e2e !important;
}

.has-luminous-vivid-orange-color {
  color: #ff6900 !important;
}

.has-luminous-vivid-amber-color {
  color: #fcb900 !important;
}

.has-light-green-cyan-color {
  color: #7bdcb5 !important;
}

.has-vivid-green-cyan-color {
  color: #00d084 !important;
}

.has-pale-cyan-blue-color {
  color: #8ed1fc !important;
}

.has-vivid-cyan-blue-color {
  color: #0693e3 !important;
}

.has-vivid-purple-color {
  color: #9b51e0 !important;
}

.has-text-align-center,
.text-center {
  text-align: center;
}

.wp-block-columns {
  flex-direction: row;
  margin: 0 auto;

  .wp-block-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.wp-block-gallery {
  align-items: flex-start !important;
  flex-wrap: wrap;
  @media (width <= 940px) {
    flex-direction: row !important;
  }

  figure {
    width: 100%;
    height: auto;
    flex: 1 1 220px;

    a {
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }

    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.wp-block-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.wp-block-embed div iframe {
  width: 100%;
  height: 400px;
}

.wp-block-button__link {
  background-color: $purple;
  color: $white;
  font-size: 20px;
  font-weight: 400;
  border-radius: 40px;
  padding: 0.7rem 1.2rem;
  max-width: max-content;
  width: 100%;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  text-align: center;

  &:hover {
    background-color: $purple-hover;
    color: $white;
  }

  @media (width < 600px) {
    font-size: 16px;
    br {
      display: none;
    }
  }
}

.is-layout-flex {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (width < 940px) {
    flex-direction: column;
  }
}

.is-content-justification-center {
  justify-content: center;
}

figure {
  &.alignleft {
    text-align: left;
  }

  &.aligncenter {
    text-align: center;
  }

  &.alignright {
    text-align: right;
  }

  &.size-full,
  &.size-medium,
  &.size-thumbnail {
    img {
      max-width: 850px;
      min-width: 200px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  @media (width <= $mobile) {
    &.size-full img {
      width: 100% !important;
      height: auto !important;
    }
  }

  @media (width <= $small) {
    &.size-medium img {
      width: 100%;
      height: auto;
    }
  }
}

.is-style-rounded figure img {
  border-radius: 50%;
}

hr.wp-block-separator {
  &:not(.is-style-wide):not(.is-style-dots) {
    max-width: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    background-color: #bbb;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  &.has-css-opacity {
    opacity: 0.5;
  }

  &.is-style-dots {
    border: none;
    text-align: center;
    &::before {
      color: currentColor;
      content: "···";
      font-family: serif;
      font-size: 1.5em;
      letter-spacing: 2em;
      padding-left: 2em;
    }
  }
}

.uag-hide-desktop {
  display: none;
}

@media (width <= $small-table) {
  .uag-hide-tab {
    display: none;
  }
}

@media (width <= $mobile) {
  .uag-hide-mob {
    display: none;
  }
}
