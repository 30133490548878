@import "@styles/variables.scss";

.arrowCompany::before {
  color: $b2b-blue-dark !important;
}

.wp-block-uagb-tabs ul.uagb-tabs__panel li {
  &.uagb-tabs__active {
    background-color: $b2b-blue;
    pointer-events: none;

    a {
      color: $white;

      img {
        filter: brightness(15);
      }
    }
  }
}

.retos-seleccion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 15px;
  @media (max-width: $mobile) {
    width: 92vw;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    justify-content: left;
    background: rgb(192 189 189 / 30%);
    padding: 10px;
    border-radius: 10px;
  }

  div {
    flex: 0 0 300px;
    padding: 15px;
    border-radius: 10px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    @media (max-width: $large-table) {
      flex: 0 0 240px;
    }
    @media (max-width: $mobile) {
      width: 300px;
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      transition: all 0.3s;
    }

    p {
      color: white;
      font-size: $fz-p-global;
      transition: all 0.5s;
      margin: 0;
      text-align: left;
    }
  }

  &:hover > :not(:hover) {
    opacity: 0.5;
    @media (max-width: $mobile) {
      opacity: 1;
    }
  }
}

.contentColumnas {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  div {
    flex: 0 0 260px;

    img {
      width: 100%;
    }
  }
}
