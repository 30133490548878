@import "@styles/variables.scss";
@import "./WpStyles/index";

/*------------------- integral evaluation -----------------------------------*/
.integral-evaluation-figures {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 2rem;
  max-width: 600px;
  width: 100%;

  & > div {
    width: auto;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }

      span {
        color: $purple;
        font-weight: bold;
        display: block;
        font-size: 40px;
      }
    }
    p {
      margin: 0;
      text-align: center;
      font-size: 21px;
      line-height: normal;
    }
  }
}

//benefits-----------------------------------
#beneficios {
  display: flex;
  gap: 25px;
  padding: 20px 0;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;

  div {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    border-radius: 10px;
    width: 310px;
    gap: 20px;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    border: 1px solid #9b9b9b;
    transition: 0.3s;

    &:hover {
      background-color: #e3e3e3;
      transform: scale(1.1);
    }

    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }

    p {
      display: block;
      text-align: left;
      font-size: 18px;
      line-height: normal;
      color: #0b2a4a;
      font-weight: 500;
    }

    @media (width <= $large-table) {
      width: 30%;
    }

    @media (width <= $small-table) {
      width: 45%;
    }

    @media (width <= $mobile) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      &:hover {
        background-color: transparent;
        transform: none;
      }
    }
  }
}

// icons list-----------------------------------------------------
.uagb-icon-list__wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .wp-block-uagb-icon-list-child {
    display: flex;
    gap: 15px;
    align-items: center;

    .uagb-icon-list__source-wrap {
      width: 20px;
      height: 20px;
    }

    .uagb-icon-list__label {
      font-size: 17px;
    }
  }
}

//plans-----------------------------------------------------------------
.containerPlans {
  width: 100%;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  .plan {
    background-color: $orange-business;
    flex: 1 1 250px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 15px;
    transition: 0.3s;

    p {
      color: $white;
    }
  }
}

.testimonialLite {
  background: $b2b-blue-dark;
  border-radius: 15px;
  padding: 2rem 2rem 4rem 2rem;
  h2 {
    color: $white;
  }
  .bgWhiteLite {
    background: $white;
    border-radius: 15px;
    padding: 2rem;

    .wp-block-column {
      width: 50%;

      @media (width <= 900px) {
        width: 100%;
      }
    }
  }
}

// videos -------------------------------------------------------
#contentBlog {
  iframe,
  blockquote.tiktok-embed {
    margin: 0 auto !important;
  }
}
