.buttonAccept {
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 25px;
  margin: 0;
  background-color: transparent;
  border: none;
  float: right;
  color: white;
  font-weight: bold;
}
