@import "@styles/variables.scss";

.mainCompany {
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    line-height: normal;
  }

  h1 {
    font-size: $b2b-fz-h1-global;
    color: $black;
    font-weight: bold;
    @media (max-width: $small-table) {
      font-size: $b2b-fz-h1-global-mobile;
    }
  }

  h2 {
    font-size: $b2b-fz-h2-global;
    color: $b2b-blue-dark;
    font-weight: bold;
    @media (max-width: $small-table) {
      font-size: $b2b-fz-h2-global-mobile;
    }
  }

  h3 {
    font-size: $b2b-fz-h3-global;
    font-weight: 400;
    @media (max-width: $small-table) {
      font-size: $b2b-fz-h3-global-mobile;
    }
  }

  p {
    font-size: 110%;
    line-height: 1.5em;
    font-weight: 400;
    color: $black;

    a {
      text-decoration: underline;
    }

    @media (max-width: $mobile) {
      font-size: 100%;
    }
  }
}
