@import "@styles/variables.scss";

.buttonAccept {
  background-color: $purple;
  color: $white;
  font-size: 16px;
  font-weight: 400;
  border-radius: 40px;
  padding: 0.7rem 1.2rem;
  width: max-content;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  justify-content: center;
  transition: $transition;
}

.buttonAccept:hover {
  background-color: $purple-hover;
}
