.wp-block-uagb-tabs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 0;

  ul.uagb-tabs__panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-content: center;

    @media (width < 930px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
      width: calc(91vw - 10px);
      white-space: nowrap;
      overflow-x: auto;
      padding: 15px;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: auto;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $b2b-blue;
        border-radius: 15px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 15px;
        background-color: $b2b-grey;
      }
    }

    li {
      width: -webkit-fill-available;
      max-width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 80px;
      box-shadow: $box-shadow;
      border-radius: 10px;
      background-color: $white;

      @media (width < 930px) {
        flex: 0 0 200px;
        box-sizing: border-box;
        white-space: break-spaces;
        height: auto;
        padding: 10px 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        text-align: center;
        line-height: normal;
        width: 100%;
        height: 100%;
        padding: 10px;
        white-space: break-spaces;
        text-decoration: none;
        color: $black;

        img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .uagb-tabs__body-wrap {
    padding: 1.5rem;
    box-shadow: $box-shadow;
    border-radius: 10px;
    background-color: $white;

    .wp-block-uagb-tabs-child {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      display: none;
      @media (max-width: $mobile) {
        flex-direction: column;
      }

      &.active {
        display: flex !important;
      }

      .columna,
      .wp-block-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 49%;
        @media (max-width: 940px) {
          width: 100%;
        }

        :is(ul, ol) {
          padding-left: 20px;
        }

        img {
          width: 100%;
          max-height: 500px;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}
